<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/ADF_North.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/ADF_North_Annotations/'"
                    [imageWidth]="6293"
                    [imageHeight]="4198" [imageScale]="0.3"
                    [watermark]="false"
                    [information]="false"
                    [filterExposures]="[]"
                    [nights]="{ count: 1, start: '2024-05-01', end: '2025-02-01' }"
                    [equipment]="{  mounts: [],
                                    telescopes: [],
                                    cameras: [] }">
</app-astro-photo>
