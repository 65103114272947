import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/hdf.json';

@Component({
  selector: 'app-hdf-image',
  templateUrl: './hdf-image.component.html',
  styleUrls: ['./hdf-image.component.sass']
})
export class HdfImageComponent {

  target: any = jsonTarget;
}
