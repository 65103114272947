import { Component, OnInit } from '@angular/core';
import PageUtils from '../../utils/pageutils';
import jsonBuildDate from '../../../environments/build-date.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  public buildDate: string = jsonBuildDate.builddate;

  constructor() { }

  ngOnInit() {
  }

  currentYear(): number {
    return new Date().getFullYear();
  }

  scrollToTop(): void {
    window.location.hash = '';
    PageUtils.scrollToTop();
  }

}
