import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { BrowserModule, Meta } from '@angular/platform-browser';
import {
  NgModule
} from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './template/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './template/footer/footer.component';
import { ContactComponent } from './template/contact/contact.component';
import { TranslateService } from './service/translate.service';
import { UnderConstructionComponent } from './template/under-construction/under-construction.component';
import { NavlinksComponent } from './template/navlinks/navlinks.component';
import { AstroPhotoComponent } from './template/astro-photo/astro-photo.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
import { AboutComponent } from './pages/about/about.component';
import { HdfComponent } from './pages/hdf/hdf.component';
import { AdfNorthComponent } from './pages/adf-north/adf-north.component';
import { AdfSouthComponent } from './pages/adf-south/adf-south.component';
import { HdfImageComponent } from './pages/image/hdf-image/hdf-image.component';
import { AdfNorthImageComponent } from './pages/image/adf-north-image/adf-north-image.component';
import { AdfSouthImageComponent } from './pages/image/adf-south-image/adf-south-image.component';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
      // Optional Step: Do some stuff with the url if needed.

      // If you lower it in the optional step 
      // you don't need to use "toLowerCase" 
      // when you pass it down to the next function
      return super.parse(url.toLowerCase()); 
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    ContactComponent,
    UnderConstructionComponent,
    NavlinksComponent,
    AstroPhotoComponent,
    NotFoundComponent,
    AboutComponent,
    HdfComponent,
    AdfNorthComponent,
    AdfSouthComponent,
    HdfImageComponent,
    AdfNorthImageComponent,
    AdfSouthImageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule
  ],
  providers: [
    TranslateService,Meta,
    {
      provide: DefaultUrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
