import { Component, OnInit,  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {

  public messageSent: boolean = false;
  public errorMessage: string = '';

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.messageSent = false;
    this.errorMessage = '';
  }

  onSubmit(form: NgForm) {
    const payload = new HttpParams()
      .set('inputName', form.value.inputName)
      .set('inputEmail', form.value.inputEmail)
      .set('inputSubject', "[ADF Website - Contact] - " + form.value.inputSubject)
      .set('inputMessage', form.value.inputMessage);
    this.http.post('../..//assets/php/contact-form.php', payload, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).subscribe(result => {
      if(result['success']) {
        this.onSuccess(form);
      } else {
        this.onError(result['message']);
      }
    },
    error => {
      this.onError(error.message);
    });
  }

  onError(message: string) {
    this.errorMessage = message;
  }

  onSuccess(form: NgForm) {
    this.messageSent = true;
    form.reset();
  }
}
