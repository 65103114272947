export default class PageUtils {

    static copyMessage(val: string, bootstrap: any) {
        var value = val.replace(/&nbsp;/g, ' ');
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        const toastBody = $('#liveToast .toast-body');
        toastBody.text(value);
        const toastLiveExample = document.getElementById('liveToast');
        const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample);
        toastBootstrap.show();
    }
    
    static scrollToTop() {
        var duration = 500;
        var stepMs = 100;
        var stepY = -1;
        let scrollToTop = window.setInterval(() => {
          let pos = window.scrollY;
          if (stepY < 0) {
            stepY = window.scrollY / (duration / stepMs);
          }
          if (pos > 0) {
            window.scrollTo(0, pos - stepY); // how far to scroll on each step
          } else {
            stepY = -1;
            window.clearInterval(scrollToTop);
          }
        }, stepMs);
    }

    static isSmallDevice() {
        return window.innerWidth < 640 || window.innerHeight < 480;
    }
    
}
