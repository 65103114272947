import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { HdfComponent } from './pages/hdf/hdf.component';
import { AdfNorthComponent } from './pages/adf-north/adf-north.component';
import { AdfSouthComponent } from './pages/adf-south/adf-south.component';
import { HdfImageComponent } from './pages/image/hdf-image/hdf-image.component';
import { AdfNorthImageComponent } from './pages/image/adf-north-image/adf-north-image.component';
import { AdfSouthImageComponent } from './pages/image/adf-south-image/adf-south-image.component';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
import { AboutComponent } from './pages/about/about.component';


const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'hdf', component: HdfComponent},
  {path: 'adf-north', component: AdfNorthComponent},
  {path: 'adf-south', component: AdfSouthComponent},
  {path: 'image/hdf', component: HdfImageComponent},
  {path: 'image/adf-north', component: AdfNorthImageComponent},
  {path: 'image/adf-south', component: AdfSouthImageComponent},
  {path: 'about', component: AboutComponent},
  
  {path: '404', component: NotFoundComponent},
  {path: '**', pathMatch: 'full', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
